import React from "react"
import { Container } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/impressum.css"

export default () => (
  <div>
    <Header />
    <Container className="text-center">
      <h1>Dachdeckerei Michael Bürgel e.K.</h1>
      <br />
      <h1>
        Allgemeine Vertragsbedingungen (AGBs) - Stand April 2019 / 04/2019
      </h1>
      <hr />
    </Container>
    <Container className="text-left">
      <h6>1 Vertragsgrundlage</h6>
      <br />
      Vertragsgrundlage für von uns als Auftragnehmer übernommene Aufträge sind
      die nachstehenden Geschäftsbedingungen. Diese AGB gelten im
      Geschäftsverkehr mit privaten (§13 BGB) und gewerblichen Kunden. Sie
      finden keine Anwendung bei einer Vergabe nach VOB/A. ein.
      <p />
      <h6>2 Angebot - Preise</h6>
      <br />
      Angebote haben eine Gültigkeit von 6 Wochen ab dem Angebotsdatum. Mit der
      Angebotsannahme gelten die Angebotspreise bis zur Beendigung der
      Baumaßnahme, wenn die Arbeiten binnen vier Monaten begonnen werden. Tritt
      danach eine wesentliche Veränderung (größer oder kleiner 1 %) der
      Preisermittlungsgrundlage im Bereich Lohnkosten ein, erhöht bzw.
      verringert sich der Angebotspreis in angemessenem Umfang. Vorbehaltlich
      eines jeder Partei zustehenden Einzelfallnachweises beträgt die
      Preisänderung 0,65% je 1% Lohnkostenänderung. Eine Umsatzsteuererhöhung
      kann an den Auftraggeber weiterberechnet werden, wenn die Leistung nach
      Ablauf von vier Monaten seit Vertragsschluss erbracht wird. Die Leistung
      ist so kalkuliert, dass bei der Ausführung Baufreiheit besteht und dass
      die Leistung zusammenhängend ohne Unterbrechung, nach Planung des
      Auftragnehmers erbracht wird. Bei Abweichungen (z.B. bei Behinderungen,
      Leistungsstörungen) besteht ein Anspruch auf Erstattung der Mehrkosten.
      Das Angebot bleibt mit allen Teilen geistiges Eigentum des Auftragnehmers.
      Die Weitergabe oder sonstige Verwendung kann im Einzelfall gestattet
      werden.
      <p />
      <h6>3 Witterungsbedingungen</h6>
      <br />
      Bei ungeeigneten Witterungs- und Trocknungsbedingungen kann der
      Auftragnehmer die Arbeiten unterbrechen. Die Dauer der Unterbrechung
      verlängert die Ausführungsfrist, wenn es sich um ungewöhnliche
      Witterungsbedingungen handelt. Die Arbeiten sind bei geeigneten
      Witterungsbedingungen unter Berücksichtigung angemessener Organisations-
      und Rüstzeiten fortzuführen.
      <p />
      <h6>4 Vergütung</h6>
      <br />
      Gemäß § 632a BGB können Abschlagsrechnungen jederzeit gestellt werden und
      sind sofort fällig und sofort zahlbar. Dies gilt auch für die
      Bereitstellung von Materialien, Stoffen oder Bauteilen. Die Schlusszahlung
      ist 10 Tage nach Rechnungszugang fällig. Skonto muss gesondert und
      ausdrücklich vereinbart sein.
      <p />
      <h6>5 Gewährleistung</h6>
      <br />
      Die Gewährleistungsfrist beginnt mit der Abnahme und ist die Frist,
      innerhalb dieser Mängel an der Leistung geltend gemacht werden können
      (Verjährungsfrist). Die Leistungen werden vom Auftragnehmer nach den
      allgemein anerkannten Regeln der Technik ausgeführt, hierfür übernimmt er
      die Gewähr. Für Beschädigungen der Leistungen, die durch unsachgemäßen
      Gebrauch, Beschädigung oder Bearbeitung durch Dritte oder durch sonstige,
      nicht durch vom Auftragnehmer zu vertretende Umstände hervorgerufen sind,
      haftet dieser nicht. Verschleiß und Abnutzungserscheinungen, die auf
      vertragsgerechtem Gebrauch und / oder natürlicher, Abnutzung beruhen, sind
      keine Mängel. Sie können bereits vor Ablauf der Gewährleistungsfrist
      eintreten. Dies gilt besonders für alle elektrisch/mechanischen
      Antriebsteile von Lichtkuppelöffnungen, Dachfensteranlagen etc. Im übrigen
      gilt die Verjährungsfrist gem. § 634a BGB wie folgt:
      <br />
      - 2 Jahre für Wartungs-, Renovierungs- und Instandhaltungsarbeiten
      (Arbeiten, die nicht die Gebäudesubstanz betreffen)
      <br />
      - 5 Jahre bei Neubauarbeiten und Arbeiten, die nach Umfang und Bedeutung
      mit Neubauarbeiten vergleichbar sind (z. B. Grundsanierung) oder Arbeiten,
      welche die Gebäudesubstanz betreffen
      <p />
      <h6>6 Aufrechnungsverbot</h6>
      <br />
      Der Auftraggeber kann die Zahlungsansprüche des Auftragnehmers nicht mit
      Forderungen aus anderen vertraglichen Beziehungen aufrechnen, es sei denn,
      die Forderung ist unbestritten oder rechtskräftig festgestellt.
      <p />
      <h6>7 Eigentumsvorbehalt</h6>
      <br />
      Soweit der Auftragnehmer im Rahmen seiner Leistungen auch Lieferungen
      erbringt, behält er sich hieran das Eigentum bis zur vollständigen Zahlung
      der erbrachten Leistungen vor. Wird ein Liefergegenstand mit einem Bauwerk
      fest verbunden, so tritt der Auftraggeber etwaige damit zusammenhängende
      eigene Forderungen (z.B. bei Weiterverkauf des Objektes) in Höhe der
      Forderung des Auftragnehmers an diesen ab.
      <p />
      <h6>8 Abnahme</h6>
      <br />
      Der Aufragnehmer hat Anspruch auf Teilabnahme für in sich abgeschlossene
      Teile der Leistung. Im Übrigen erfolgt die Abnahme nach § 640 BGB. Der
      Abnahme steht es gleich, wenn der Besteller das Werk nicht innerhalb einer
      ihm vom Unternehmer bestimmten angemessenen Frist abnimmt. Die Abnahme
      kann auch durch schlüssiges Verhalten erfolgen.
      <p />
      <h6>9 Leistungsermittlung, Aufmaß und Abrechnung</h6>
      <br />
      Bei einem Pauschalpreisvertrag erfolgt die Abrechnung nach den
      vertraglichen Vereinbarungen. Ist ein Einheitspreisvertrag vereinbart,
      erfolgt die Abrechung auf Basis einer Leistungsermittlung durch Aufmass.
      Dabei wird die Leistung nach den Maßen der fertigen Oberfläche berechnet.
      Als Ausgleich für den nicht berechneten Bearbeitungsaufwand zur
      Anarbeitung an nicht behandelte Teilflächen (so genannte Aussparungen),
      zum Beispiel Fenster- und Türöffnungen, Lüftungsöffnungen werden diese
      Flächen bis zu einer Einzelgröße von 2,5 qm übermessen. Bei Längenmaßen
      bleiben Unterbrechungen bis 1 m Einzelgröße unberücksichtigt. Auftraggeber
      und Auftragnehmer können weitere detaillierte Aufmassregeln durch
      Vereinbarung der jeweils einschlägigen ATV VOB/C-Norm zugrunde legen.
      <p />
      <h6>10 Sonstiges</h6>
      <br />
      Ist der Auftraggeber Verbraucher, so gilt der gesetzliche Gerichtsstand.
      Ansonsten ist Erfüllungsort und ausschließlicher Gerichtsstand für alle
      Streitigkeiten der Geschäftssitz des Auftragnehmers, sofern sich aus der
      Auftragsbestätigung nichts anderes ergibt. Sollte eine der vorstehenden
      Regelungen - gleich aus welchem Rechtsgrund - unwirksam sein, so wird
      dadurch die Wirksamkeit und Verbindlichkeit der übrigen Bestimmungen nicht
      berührt.{" "}
      <b>
        Ausschluss von Verbraucherschlichtungsverfahren – Information gemäß § 36
        VSBG:
      </b>{" "}
      Der Auftragnehmer ist weder gesetzlich verpflichtet noch beteiligt er sich
      freiwillig an Verbraucherschlichtungsverfahren nach dem
      Verbraucherstreitbeilegungsgesetz (VSBG).
      <p />
    </Container>
    <Footer />
  </div>
)
